<template>
  <div class="about-me">

    <div class="column image-column">
      <img :src="require('@/assets/img/my_profile.jpg')"> <!-- This is how you reference an image in the assets folder -->
    </div>
    <div class="column text-column">
      <div v-html="aboutMeHtml"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AboutMe',
  data() {
    return {
      aboutMeHtml: '',
      apiUrlGetAboutMe: `${process.env.VUE_APP_BACKEND_HOST}${process.env.VUE_APP_ABOUT_ME_ENDPOINT}`,
    };
  },
  async created() {
    const { data } = await axios.get(this.apiUrlGetAboutMe);
    this.aboutMeHtml = data;
  },
}

</script>

<style scoped>
.about-me {
  display: flex;
  gap: 20px;
  align-items: center; /* This will vertically center the content of the columns */
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will vertically center the content inside each column */
}

.image-column img {
  max-width: 100%;
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .about-me {
    flex-direction: column;
  }
}
</style>
