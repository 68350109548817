<template>
  <div v-if="schema.type === 'object'">
    <div v-for="(subSchema, key) in schema.properties" :key="key">
      <FormField :schema="value" :modelValue="formData[model.title][key]"
                     ref="formFields"  @update:modelValue="formData[model.title][key] = $event"
          />
    </div>
  </div>

  <div v-else-if="schema.type === 'boolean'" :class="{ 'error-container': hasError }">
      <div :class="{ 'error-container': hasError }">
        <label class="form-label">{{ schema.title }}</label>
      <label class="switch">
        <input type="checkbox" v-model="localValue" />
        <span class="slider round"></span>
      </label>
      </div>
  </div>
  <div v-else-if="schema.type === 'string' && schema.format === 'multi-line'" :class="{ 'error-container': hasError }">
        <label class="form-label">{{ schema.title }}</label>
        <textarea v-model="localValue" class="form-control" rows="10"></textarea>
  </div>
   <div v-else-if="schema.type === 'string' && schema.format === 'date-time'" :class="{ 'error-container': hasError }">
      <label class="form-label">{{ schema.title }}</label>
      <input type="date" v-model="localValue" class="form-control"/>
  </div>

  <div v-else-if="schema.type === 'string' && schema.enum" :class="{ 'error-container': hasError }">
    <label class="form-label"> {{ schema.title }} </label>
    <div v-for="option in schema.enum" :key="option">
      <label class="form-check-label">{{ option }}
      <input type="radio" v-model="localValue" :value="option" class="form-check-input">
      </label>
    </div>
  </div>
  <div v-else-if="schema.type === 'string'" :class="{ 'error-container': hasError }">
      <label class="form-label">{{ schema.title }}</label>
      <input type="text" v-model="localValue" class="form-control"/>
  </div>

  <div v-else-if="schema.type === 'integer' && schema.multipleOf" :class="{ 'error-container': hasError }">
    <label class="form-label">
      {{ schema.title }}
      <span>{{ localValue }} / {{schema.maximum}}</span>
      <input type="range" :min="schema.minimum" :max="schema.maximum" :step="schema.multipleOf" v-model="localValue" />
    </label>
  </div>
  <div v-else-if="schema.type === 'integer'" :class="{ 'error-container': hasError }">
    <label class="form-label">{{ schema.title }}</label>
     <input type="number" v-model="localValue" />
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    error: String,
    schema: {
      type: Object,
      required: true,
    },
    modelValue: {
      required: true,
    },
  },
  data() {
  return {
    hasError: false,
  };
},
  computed: {
    isRequired() {
     return (this.schema.default === undefined || this.schema.default === '');
    },
    isValid() {
      if (this.isRequired) {
        return !!this.localValue;
      }
      return true;
    },
    localValue: {
      get() {
        return this.modelValue === undefined || this.modelValue === null  || this.modelValue === '' ? this.schema.default : this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('validation', this.isValid); // emit the validation state
      },
    },
  },

  mounted() {
    if (this.modelValue === null || this.modelValue === undefined) {
      this.updateModelValue(this.schema.default);
    }
  },

  methods: {
    updateValue(key, value) {
      this.$set(this.model, key, value);
      this.$emit('update:modelValue', this.model);
    },
    updateModelValue(newValue) {
      this.$emit('update:modelValue', newValue);
    },
    validate() {
    if (this.isRequired && !this.localValue) {
      // return an error message if the field is required and empty
      this.hasError = true;
      return `${this.schema.title} is required`;
    }
    this.hasError = false;
    },
  },
};
</script>


<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

label {
  margin-top: 1em;
}

input[type='text'],
input[type='date'],
input[type='range'],
input[type='number'] {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.5em;
}

button {
  width: 100%;
  margin-top: 1em;
  padding: 0.5em 1em;
}

/* A generic container for fields to show errors */
.error-container {
  border: 1px solid red;
  display: flex; /* so the container fits the content */
  flex-direction: column;
  margin: 5px 0;
  padding: 5px;
  background-color: rgba(255, 0, 0, 0.1); /* light red background */
}

/* Error styling specific to checkboxes */
.error-container input[type='checkbox'] {
  margin-right: 5px;
}
.error-container input[type="text"] {
  width: 100%;
  box-sizing: border-box;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.textarea {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.5em;
}
</style>