// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

import AboutMe from "@/components/AboutMe.vue";
import HomePage from "@/components/HomePage.vue";
import HealthQuestionnaire from '@/components/HealthQuestionnaire.vue';
import BiofeedbackSignupForm from "@/components/BiofeedbackSignupForm.vue";
import BiofeedbackDesc from "@/components/BiofeedbackDesc.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/aboutme',
    name: 'AboutMe',
    component: AboutMe,
  },
  {
    path: '/health-questionnaire',
    name: 'HealthQuestionnaire',
    component: HealthQuestionnaire,
  },
  {
    path: '/sign-up-for-study',
    name: 'BiofeedbackSignupForm',
    component: BiofeedbackSignupForm,
  },
  {
    path: '/biofeedback',
    name: 'BiofeedbackDesc',
    component: BiofeedbackDesc,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
