<template>

  <div class="biofeedback-desc">

    <div class="column image-column">
      <img :src="require('@/assets/img/biofeedback.png')" alt="BiofeedbackImage">
    </div>
    <div class="column text-column">
     <p>Welcome to the world of biofeedback, where the mechanics of your body's inner workings are unveiled.</p>
        <p>At the heart of our being, myriad biological processes are constantly unfolding. Each moment, these intricate mechanisms shift our body's state between the sympathetic—commonly known as the "fight or flight" response—and the parasympathetic or the "rest and digest" state. Understanding these transformations and the signals that accompany them is vital for our overall wellness.</p>
        <p>So, what is biofeedback?
In a biofeedback session, we tap into the subtle changes within our body. Using specialized sensors, we measure various parameters, from heartbeat, blood pressure, and skin temperature to muscle tone and other activities. By combining this with additional data related to your sleep patterns and movement patterns during the day, a comprehensive picture of your body's state emerges.
       </p>
        <p>These diverse data streams are then processed through advanced algorithms or 'biomarkers'. Their role? To identify known patterns that offer insights into your well-being. Once decoded, this information is shared with you, translating into actionable feedback.</p>
        <p>Wondering about its applications? Whether you're aiming to optimize your sleep, manage anxiety, decide on your workout regimen, or understand the impact of newly adopted habits (or the cessation of old ones), biofeedback provides valuable insights. It's like having a conversation with your body, understanding its needs, reactions, and signals.
However, it's essential to remember, biofeedback isn't a magical solution that will instantly transform you. Instead, think of it as a finely tuned instrument, a bridge to connect with your body's rhythms and messages. When approached with dedication and discipline, the benefits of biofeedback are profound, paving the way for a better physical and mental well-being over time.
Dive in and embark on this exciting journey with biofeedback. Listen to your body, understand its messages, and harness this knowledge for a healthier, more balanced life.
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BiofeedbackDesc'
}
</script>

<style scoped>
.biofeedback-desc {
  display: flex;
  gap: 20px;
  align-items: center; /* This will vertically center the content of the columns */
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will vertically center the content inside each column */
}

.image-column img {
  max-width: 100%;
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .biofeedback-desc {
    flex-direction: column;
  }
}
</style>
