<template>
  <div class="home-message">
    <div class="column image-column">
      <img :src="require('@/assets/img/home_background.png')" alt="HomeImage">
    </div>
    <div class="column text-column">
     <p>
       The harmonious balance between the mind and the body is a profound concept that can greatly enhance one's overall well-being. The mind, with its innate inclination towards stillness, craves tranquillity and calmness, allowing it to function optimally and achieve higher levels of mental clarity. Conversely, the body thrives on movement, as it releases energy, stimulates growth, and fosters a sense of vitality. By embracing the dual nature of our existence, we can cultivate a lifestyle that incorporates both movement and stillness. This delicate interplay enables us to maintain a still mind, even as we engage in physical activities, ultimately leading to a state of equilibrium, inner peace, and heightened self-awareness.
     </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>


<style scoped>
.home-message {
  display: flex;
  gap: 20px;
  align-items: center; /* This will vertically center the content of the columns */
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will vertically center the content inside each column */
}

.image-column img {
  max-width: 100%;
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .home-message {
    flex-direction: column;
  }
}
</style>

