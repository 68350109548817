<template>

  <div class="biofeedback-signup-form">
    <!-- 1st column for the image -->
    <div class="image-column">
      <img :src="require('@/assets/img/biofeedback.png')" alt="BiofeedbackImage">
    </div>

    <!-- 2nd column for the form -->
    <div class="form-column">
      <form @submit.prevent="submitForm">
      <!-- Name -->
      <label>
        <input type="text" v-model="formData.name" placeholder="Enter your name" class="full-width-input">
      </label>

      <!-- Email -->
      <label>
        <input type="email" v-model="formData.email" placeholder="Enter your email address" class="full-width-input">
      </label>

      <!-- Monthly Budget -->
      <label>
        Monthly Budget to Spend for a Year or Six Months:
        <select v-model="formData.budget">
          <option value="below50">Below $50</option>
          <option value="50to100">$50 to $100</option>
          <option value="100to200">$100 to $200</option>
          <option value="200to500">$200 to $500</option>
          <option value="above500">Above $500</option>
        </select>
      </label>

        <!-- Commitment Level -->
        <div class="question-row">
          <p>How Committed Are You to Improve Your Well-being?</p>
          <div v-for="option in commitmentOptions" :key="option.value" class="option-row">
            <label>
              <input type="radio" v-model="formData.commitment" :value="option.value">
              {{ option.text }}
            </label>
          </div>
        </div>

        <!-- Important Thing for Health -->
        <div class="question-row">
          <p>In Your Opinion, What is the Most Important Thing to Improve Your Health?</p>
          <div class="option-row">
            <select v-model="formData.healthImprovementFactor">
              <option v-for="option in healthOptions" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <!-- Guidance Level -->
        <div class="question-row">
          <p>How Much Guidance Do You Need to Improve Your Health?</p>
          <div v-for="option in guidanceOptions" :key="option.value" class="option-row">
            <label>
              <input type="radio" v-model="formData.guidance" :value="option.value">
              {{ option.text }}
            </label>
          </div>
        </div>


      <!-- Submit button -->
    <button type="submit" @click.prevent="submitForm" :disabled="serverMessage">Sign Up</button>

    <!-- Display message from the server -->
    <p v-if="serverMessage" class="server-message">{{ serverMessage }}</p>
    </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      serverMessage: undefined,
      apiUrlSignupStudyForm: `${process.env.VUE_APP_BACKEND_HOST}${process.env.VUE_APP_SIGNUP_STUDY_SUBMIT_ENDPOINT}`,
      formData: {
        name: '',
        email: '',
        budget: '',
        commitment: '',
        healthImprovementFactor: '',
        guidance: ''
      },
      commitmentOptions: [
        { value: 'very', text: 'Very Committed' },
        { value: 'somewhat', text: 'Somewhat Committed' },
        { value: 'neutral', text: 'Neutral' },
        { value: 'notVery', text: 'Not Very Committed' },
        { value: 'notAtAll', text: 'Not Committed at All' }
      ],
      healthOptions: [
        { value: 'exercise', text: 'Regular Exercise' },
        { value: 'diet', text: 'Balanced Diet' },
        { value: 'mentalHealth', text: 'Mental Health and Meditation' },
        { value: 'checkups', text: 'Regular Medical Check-ups' },
        { value: 'sleep', text: 'Adequate Sleep' },
        { value: 'other', text: 'Other' }
      ],
      guidanceOptions: [
        { value: 'constant', text: 'I need constant guidance and mentoring' },
        { value: 'manageSome', text: 'I need guidance but can manage a few things on my own' },
        { value: 'occasional', text: 'I need occasional guidance' },
        { value: 'mostlyManage', text: 'I can mostly manage on my own but need a nudge once in a while' },
        { value: 'selfDriven', text: 'I am self-driven and need minimal guidance' }
      ],
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post(this.apiUrlSignupStudyForm, this.formData);

        // Clear the form data
        this.formData = {
          commitment: '',
          name: '',
          email: ''
        };

        // Display the message from the server
        this.serverMessage = response.data.message;
      } catch (error) {
        console.error("Error submitting the form:", error);
        this.serverMessage = "Error submitting the form. Please try again later.";
      }
    }
  }
}
</script>

<style scoped>

.biofeedback-signup-form {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure it takes up the full width */
}

form {
  display: flex;
  flex-direction: column;
}

.image-column {
  flex: 1;
  padding: 10px;
}

.form-column {
  flex: 1;
  padding: 10px;
}

.input-group {
  margin-bottom: 10px;
}


.image-column img {
  max-width: 100%;
  height: auto;
}

.form-column form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Basic Styling */
.question-row {
  margin-bottom: 20px;
}

.option-row {
  margin-top: 5px;
}

/* Flexbox for Better Layout */
.question-row {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.server-message {
  color: blue;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}


/* Responsive styles */
@media (max-width: 768px) {
  .biofeedback-signup-form {
    flex-direction: column;
  }
}
</style>
