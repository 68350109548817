<template>
  <div class="container">
    <form @submit.prevent="submitForm">
      <div v-for="(model, index) in schema" :key="index">
        <h2>{{ model.title }}</h2>
        <div v-for="(value, key) in model.properties" :key="key">
          <FormField :schema="value" :modelValue="formData[model.title][model.properties[key].title]"
                     ref="formFields"  @update:modelValue="formData[model.title][model.properties[key].title] = $event"
          />
        </div>
      </div>
      <button type="submit" :disabled="serverMessage">Submit</button>
      <!-- Display message from the server -->
      <p v-if="serverMessage" class="server-message">{{ serverMessage }}</p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import FormField from "@/components/FormField.vue";

export default {
  components: {FormField},
  data() {
    return {
      schema: [],
      formData: {},
      serverMessage: undefined,
      apiUrlGetSchema: `${process.env.VUE_APP_BACKEND_HOST}${process.env.VUE_APP_HEALTH_QA_SCHEMA_ENDPOINT}`,
      apiUrlSubmitForm: `${process.env.VUE_APP_BACKEND_HOST}${process.env.VUE_APP_HEALTH_QA_SUBMIT_ENDPOINT}`
    };
  },
  async created() {
    const { data } = await axios.get(this.apiUrlGetSchema);
    this.schema = data;
    for (const model of data) {
      this.formData[model.title] = {};
      for (const key in model.properties) {
        const subTitle = model.properties[key].title;
        if (model.properties[key].default !== undefined) {
          this.formData[model.title][subTitle] = model.properties[key].default;
        } else {
          this.formData[model.title][subTitle] = '';
        }
      }
    }
  },
  methods: {
    async submitForm() {
      const errors = {};
      this.$refs.formFields.forEach((fieldComponent, key) => {
      const errorMessage = fieldComponent.validate();

      if (errorMessage) {
        errors[key] = errorMessage;
      }
    });
    if (Object.keys(errors).length) {
      this.errors = errors;
      // prevent form submission
      return;
    }
    try {
      let response = await axios.post(this.apiUrlSubmitForm, this.formData);

        // Display the message from the server
        this.serverMessage = response.data.message;
      } catch (error) {
        console.error("Error submitting the form:", error);
        this.serverMessage = "Error submitting the form. Please try again later.";
      }
    },

    getInputType(type) {
      switch (type) {
        case 'integer':
          return 'number';
        case 'boolean':
          return 'checkbox';
        default:
          return 'text';
      }
    },
  },
};
</script>

<style scoped>

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
}

form {
  display: flex;
  flex-direction: column;
}
/* Button styles */
button {
  background-color: #444; /* A shade of gray for the button background */
  color: white;           /* White text color for button text */
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}
.server-message {
  color: blue;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
